import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { TagOrderWithUtmDocument, UtmParameter } from '../../../common/gql/graphql';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../data/data.service';
import {
    TagCustomerWithUtmDocument,
    TagSessionWithUtmDocument,
} from '../../../../../../../libs/plugin-utm-tracking/src/e2e/graphql/gql-shop/graphql';
import { isPlatformBrowser } from '@angular/common';
import { ActiveService } from '../active-order/active.service';
import { merge, Subject } from 'rxjs';

/**
 * Type definition for the localstorage object
 */
export type UtmStorage = {
    timestamp: number;
    utmParams: UtmParameter;
};

@Injectable({
  providedIn: 'root',
})
export class UtmTrackingService {
    private readonly storageKey = 'kb_utm';

    private utmParams$: Subject<UtmParameter | undefined> = new Subject();
    private _utmParams: UtmParameter | undefined;

    private set utmParams(value: UtmParameter | undefined) {
        this._utmParams = value;
        this.utmParams$.next(value);
    }

    public get utmParams() {
        return this._utmParams;
    }

    constructor(private route: ActivatedRoute,
                private dataService: DataService,
                private activeService: ActiveService,
                @Inject(PLATFORM_ID) private platformId: any) {
        this.activeService.activeCustomer$
            .pipe(
                withLatestFrom(this.utmParams$),
                filter(([customer, params]) => !!customer && !!params)
            )
            .subscribe(() => this.tagActiveCustomer());

        this.activeService.activeOrder$
            .pipe(
                withLatestFrom(this.utmParams$),
                filter(([order, params]) => !!order && !!params)
            )
            .subscribe(() => this.tagActiveOrder());

    }

    /**
     * Tag a session with utm parameters
     * @param parameter
     */
    public tagSessionWithUtm(parameter: UtmParameter) {
        this.dataService.mutate(TagSessionWithUtmDocument, { params: parameter }).subscribe();
    }

    /**
     * Tag the active customer with utm parameters
     */
    public tagActiveCustomer() {
        if (isPlatformBrowser(this.platformId) && this.utmParams) {
            this.dataService.mutate(TagCustomerWithUtmDocument, {params: this.utmParams}).subscribe();
        }
    }

    /**
     * Tag the active order with utm parameters
     */
    public tagActiveOrder() {
        if (isPlatformBrowser(this.platformId) && this.utmParams) {
            this.dataService.mutate(TagOrderWithUtmDocument, {params: this.utmParams}).subscribe();
        }
    }


    /**
     * @description
     * Initializes the UTM tracking service by reading the UTM parameters from the URL query string.
     * 1. Restore parameters from a former visit (in case of refresh)
     * 2. If the stored parameters are older than X days, we ignore the parameters
     * 3. Read the parameters from URL, if exist parse and tag the session
     * 4. Store the parameters to localstorage in case the user refreshes or revisit the page later
     * without utm parameters
     */
    initialize() {
        this.restore();
        this.route.queryParams.pipe(filter(notNullOrUndefined)).subscribe(params => {
            // Either the required UTM parameter need to be defined or the gclid
            if (
                (params.utm_campaign && params.utm_medium && params.utm_source) ||
                params.gclid
            ) {
                this.utmParams = {
                    campaign: params.utm_campaign,
                    medium: params.utm_medium,
                    source: params.utm_source,
                    term: params.utm_term,
                    content: params.utm_content,
                    gclid: params.gclid,
                };
                this.store();
            }
            if (isPlatformBrowser(this.platformId) && this.utmParams) {
                this.tagSessionWithUtm(this.utmParams);
            }
        });
    }

    /**
     * Store the UTM parameters to localstorage
     */
    private store() {
        if (isPlatformBrowser(this.platformId) && this.utmParams) {
            const storage: UtmStorage = {
                timestamp: Date.now(),
                utmParams: this.utmParams,
            };
            localStorage.setItem(this.storageKey, JSON.stringify(storage));
        }
    }

    /**
     * Restore the UTM parameters from localstorage and clear it if the timestamp is older than a week
     */
    private restore() {
        if (isPlatformBrowser(this.platformId)) {
            const json = localStorage.getItem(this.storageKey);
            if (json) {
                try {
                    const storage: UtmStorage = JSON.parse(json);
                    this.utmParams = storage.utmParams;

                    if (storage.timestamp < Date.now() - 1000 * 60 * 60 * 24 * 7) {
                        // A revisit after one week we don't count towards the campaign
                        this.clear();
                    }
                } catch (e) {
                    // Remove the invalid storage JSON
                    localStorage.removeItem(this.storageKey);
                }
            }
        }
    }

    /**
     * Clear the UTM parameters from localstorage
     */
    clear() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(this.storageKey);
            this.utmParams = undefined;
        }
    }

}
